import React from 'react';
import education from "../assets/img/education-logo.png";
import health from "../assets/img/healthcare.png";
import real from "../assets/img/real-estate-logo.png";
import incub from "../assets/img/incubator.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/1711.jpg"

export const Industries = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="industry" id="industries">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="industry-bx wow zoomIn">
                        <h2>Our Businesses</h2>
                        <p>Aditya Ventures currently owns and operates Skypark Residential Society, Netaji Public School,<br></br> BK Aditya Nursing Home as well as Aditya Chambers.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme industry-slider">
                            <div className="item">
                                <img src={real} alt="real estate" />
                                <h5>Real Estate</h5>
                            </div>
                            <div className="item">
                                <img src={education} alt="education" />
                                <h5>Education</h5>
                            </div>
                            <div className="item">
                                <img src={health} alt="healthcare" />
                                <h5>Healthcare</h5>
                            </div>
                            <div className="item">
                                <img src={incub} alt="incubator" />
                                <h5>Incubator</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="background" />
    </section>
  )
}
