import React from 'react';
import {VerticalTimeline,VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const Roadmap = () => {
    return (
        <div className='roadmap'>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="1991"
                    dateClassName='custom-date-color'
                    iconStyle={{ background: '#0066FF', color: 'black' }}
                >
                    <h3 className="vertical-timeline-element-title">Residential Real Estate </h3>
                    <p className="vertical-timeline-element-description">
                    Aditya Apartment, Anandam Residency, Aditya Plaza, Uttarayan Apartments, Ashirbaad Apartments, Abhiskar Apartments, Arunoday Apartments, Aditya Bhavan, Udayan, Royal Guest House, Supermarket, Aditya SkyPark Residency.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2000"
                    dateClassName='custom-date-color'
                    iconStyle={{ background: '#0066FF', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title">Netaji Public School</h3>
                    <p className="vertical-timeline-element-description">
                    Educational Institution from Nursery to Higher Secondary
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2005"
                    dateClassName='custom-date-color'
                    iconStyle={{ background: '#0066FF', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title">Commercial Property</h3>
                    <p className="vertical-timeline-element-description">
                    Built 15+ commercial office spaces including banks, financial service firms, gym, telecom companies, etc
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2019"
                    dateClassName='custom-date-color'
                    iconStyle={{ background: '#0066FF', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title">Olivia Nursing Home</h3>
                    <p className="vertical-timeline-element-description">
                    Healthcare facilities
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}